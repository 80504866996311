<template>
  <v-card
    flat
    class="pa-3 mt-2 mx-auto my-auto"
    max-width="600"
  >
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"

        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="12"
            cols="12"
          >
            <v-select
              v-model="form.entering_level_0"
              :items="items"
              item-text="label"
              item-value="value"
              item-color="color"
              label="مستوى الزيارة (السيطرات الداخلية)"
              outlined
            >
              <template #item="{item}">
                <span :style="{ color: item.color }">{{ item.label }}</span>
              </template>
            </v-select>
          </v-col>
          <v-divider></v-divider>
          <v-col
            md="12"
            cols="12"
          >
            <v-select
              v-model="form.entering_level_1"
              :items="items"
              item-text="label"
              item-value="value"
              item-color="color"
              label="مستوى الزيارة  (السيطرات الخارجية)"
              outlined
            >
              <template #item="{item}">
                <span :style="{ color: item.color }">{{ item.label }}</span>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              :disabled="!valid"
              @click="submit"
            >
              حفظ التغييرات
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'SiteSettings',
  data: () => ({
    valid: true,
    form: {
      entering_level_0: '',
      entering_level_1: '',
    },
    items: [
      {
        label: 'لا يوجد',
        value: 0,
        color: 'black',
      },
      {
        label: 'المستوى الاول',
        value: 1,
        color: 'primary',
      },
      {
        label: 'المستوى الثاني',
        value: 2,
        color: 'warning',
      },
      {
        label: 'المستوى الثالث',
        value: 3,
        color: 'error',
      },
    ],
    rules: {
      entering_level_0: [
        v => !!v || 'المستوى  مطلوب',
      ],
      entering_level_1: [
        v => !!v || 'المستوى  مطلوب',
      ],
    },
  }),
  async mounted() {
    await this.fetch()
  },

  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate() === false) {
          return
        }
        await axios.post('/level/set', this.form);
        await toast.dispatch('success', 'تم التحديث بنجاح');
      } catch (error) {
        // await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      }
    },
  },
}
</script>

<style scoped>

</style>
